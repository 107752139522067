import book from "./book.png";
import "./App.css";
import { Button } from "@material-ui/core";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div
          stlye={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h1 class="aqhdfnt">
            The Prophetic Imagery
            <br />
            of Anthony Quinn
          </h1>
          <a href="https://www.anthonyquinnbooks.com/">
            <Button
              style={{
                borderRadius: 5,
                backgroundColor: "#21b6ae",
                padding: "18px 36px",
                fontSize: "18px",
                color: "#fff",
                marginRight: "5px",
                marginLeft: "5px",
                marginBottom: "8px",
              }}
              variant="contained"
            >
              Home
            </Button>
          </a>
          <a href="#book-reviews">
            <Button
              style={{
                borderRadius: 5,
                backgroundColor: "#21b6ae",
                padding: "18px 36px",
                fontSize: "18px",
                color: "#fff",
                marginRight: "5px",
                marginLeft: "5px",
                marginBottom: "8px",
              }}
              variant="contained"
            >
              Reviews
            </Button>
          </a>
          <a href="https://www.amazon.com/Prophetic-Imagery-Anthony-Quinn-Precognitive/dp/1483598330/ref=sr_1_1?ie=UTF8&qid=1499458397&sr=8-1&keywords=The+Prophetic+Imagery+of+Anthony+Quinn">
            <Button
              style={{
                borderRadius: 5,
                backgroundColor: "#21b6ae",
                padding: "18px 36px",
                fontSize: "18px",
                color: "#fff",
                marginRight: "5px",
                marginLeft: "5px",
                marginBottom: "8px",
              }}
              variant="contained"
            >
              Buy Now
            </Button>
          </a>
        </div>

        <img src={book} className="App-logo" alt="Book" />

        <h2>A Study of Surrealism and Precognitive Art</h2>
        <h3>Author: Glenn Harte</h3>
        <p>
          Anthony Quinn was an amazing man of destiny. He was an Academy Award
          winning actor, a writer, an amateur architect, a self taught historian
          and a painter and sculptor of immense talent. All throughout his adult
          life he spoke of dreams and apparitions that followed him. He wrote of
          these experiences in depth. Today, years after his death, we find that
          these dreams may actually have been precognitive and that he actually
          painted those visions. Discovered in the last few years are paintings
          that are hard to explain – paintings of events and people that he
          never saw in his own lifetime. Among these works is a painting that
          depicts the events of 9/11 as if Anthony Quinn took the headlines and
          images of the day and put them on canvas. But how could this be? He
          died 3 months before these events had even occurred. The science of
          precognitive dreams explains Anthony Quinn’s special talent in a
          logical, reasonable and coherent way, supported by experiments and by
          documented data. This is the story of the discovery of the
          precognitive paintings of Anthony Quinn and the history of the man
          that created these impossible works – as it related to the science
          that even makes it possible. You will see the genius of this great man
          as never before. You will read his own words as he describes events
          that remained in his future and you will see the paintings he created
          with his own hand. His work demonstrates – as no other artist in
          history, the potential of the surrealist movement and you may come to
          believe, as the author, that Anthony Quinn may have created one of the
          most remarkable paintings of all time. Discover for yourself the art
          of Anthony Quinn and see his genius and his special talent, like never
          before.
        </p>

        <p>
          In 2003, as the author browsed through the warehouse of the deceased
          actor and artist, Anthony Quinn, he saw a painting, which depicted the
          events of September 11th, 2001. The painting was signed by Anthony
          Quinn himself, but this was impossible. He had died three months
          before the events of 9/11 had even occurred. Research of the painting
          disclosed that Anthony Quinn had painted the painting, called “Facets
          of Liberty” in 1985 and produced lithograph copies a year later in
          1986. Not only had he died before 9/11, but he had painted the amazing
          montage a full 16 years before the fateful day. This book reflects the
          search for understanding and studies the circumstances and personality
          of the man, Anthony Quinn and how his entire life was a foundation for
          the science that made this painting possible. Quinn tells us in his
          own words of his dreams and life circumstances that produced the
          unique ingredients for precognitive thought and set the stage for a
          painting of historic proportions.
        </p>

        <p>
          GLENN HARTE has been the agent for hundreds of artists around the
          world and has been the agent for the art of Anthony Quinn since 2003.
          He has worked with celebrity artists including Anthony Hopkins, Tony
          Curtis and Burt Young. An art collector and a curator for fine
          masterworks, he is also extensively versed in the work of Picasso,
          Rembrandt, Salvador Dali, Miro, Chagall and Albrecht Durer.
        </p>
      </header>
      <a name="book-reviews"></a>
      <div className="App-body">
        <h1>Reviews</h1>
        <p>
          <strong>CataclysmicKnight - OnlineBookClub.org:</strong>
          <br />4 out of 4 stars
        </p>
        <p>
          "Anthony Quinn was an incredibly prolific actor who travelled the
          world. He befriended and spoke with world leaders and was a man with
          high standards and a strong moral center. What many may not be aware
          of, however, is that the man with over 160 acting credits that include
          movies like Lawrence of Arabia, Zorba the Greek and La Strada was also
          one heck of an artist. While many of his pieces sold for hundreds of
          thousands of dollars, it was one piece in particular that blew Glenn
          Harte away: Facets of Liberty. This painting and the precognative
          nature of it are the focus of Glenn’s book The Prophetic Imagery of
          Anthony Quinn.
        </p>
        <p>
          Anthony Quinn painted Facets of Liberty a full 16 years before the
          events of 9/11, and Quinn didn’t even live to see 9/11 himself.
          Despite this, Glenn is able to point out numerous segments of the
          painting that relate perfectly to the event. The painting in question
          is a montage with the Statue of Liberty peering off toward what looks
          like a dark figure under the face of the Statue of Liberty itself. In
          the lower-left corner is a mass of brush strokes that seem almost
          meaningless at first glance, but after Glenn’s explanation it’s clear
          they could represent firefighters, onlookers or people who escaped
          from the burning buildings. Glenn highlights or clips out different
          parts of the painting, putting them side-by-side with images from that
          horrific day or people related to it and compares them to make his
          points. While one or two points feel like a bit of a stretch, overall
          I genuinely saw what Glenn was talking about and was rather blown away
          myself! As someone who has no experience with interpreting art, it was
          a really fascinating journey.
        </p>
        <p>
          Aside from describing the different ways that, in hindsight, Facets of
          Liberty is a prophetic painting, Glenn discusses the amazing life of
          Anthony Quinn, gives some information about precognition (seeing
          things before they happen) and remote viewing (“witnessing events not
          directly before the viewer”) and mentions other famous works of
          precognition. As such, the book stays fresh and interesting without
          going so deep into art or precognition in general that it becomes dry
          or loses its focus. While I’ve never actually watched any of Quinn’s
          movies, his life was really fascinating and Glenn’s brief look at it
          made me want to know even more. It’s clear that Glenn did a lot of
          research, and he lists two of Quinn’s autobiographies as his main
          sources of information along with speaking with Quinn’s wife and those
          who knew him.
        </p>
        <p>
          In addition to Facets of Liberty, there’s another piece that Quinn
          painted that was incredible. The painting is of a pre-teen boy, which
          may seem uninteresting on its own, but what makes it astonishing is
          that it looks almost identical to his son at that age. This is made
          truly mind-blowing when you learn the painting was done 50+ years
          before his son was born and it was inspired by “the boy”, someone he
          wrote about seeing often in his autobiographies! Like Facets of
          Liberty, Glenn has an image in the book of the painting and Quinn’s
          son side-by-side to show just how spot-on it is.
        </p>
        <p>
          What amazed me the most was how much the book made me believe. This
          was done through Glenn’s demonstrations comparing the paintings, the
          recounting of his amazing life and pointing out other famous artists
          who created things via various forms of precognition. The latter topic
          is what really sealed the deal, and all but one of the examples were
          new to me. For example, a man named Morgan Robertson wrote a book
          called The Wreck of the Titan 14 years before the Titanic famously
          sunk. The book was “about the sinking of an unsinkable ocean liner
          named the Titan” which sank while carrying hundreds of wealthy
          travellers after hitting an iceberg.
        </p>
        <p>
          The one thing that worried me about The Prophetic Imagery of Anthony
          Quinn was that Glenn is actually the agent for Quinn’s artwork, which
          is something he states at the beginning of the book. He took on the
          role in June 2003, and since then has done an expert job familiarising
          himself with barns full of Quinn’s work. When I researched this book
          before choosing to review it a reviewer said that the book felt like
          Glenn was merely trying to increase the value of the artwork, but
          after reading the book myself I couldn’t disagree more. Glenn comes
          off as a friend and a fan of Quinn, someone who appreciates his
          artwork and life and was merely blown away when he saw Facets of
          Liberty, which is essentially how he says it himself. It would be more
          like getting a dream job, realizing something awesome about your boss
          and then letting everyone know about it genuinely and honestly. I’m
          really glad I gave this book a shot, it turned me into a fan of Quinn
          and a believer of Facets of Liberty and artistic precognition in
          general.
        </p>
        <p>
          While I enjoyed the book, and I found absolutely no errors at all, I
          really would’ve liked it if there was a bit more to it. I’m definitely
          more interested in Quinn’s life, and since there are at least two
          autobiographies covering that very topic I can understand why Glenn
          didn’t go more in depth than he did (there were already two chapters
          solely about Quinn’s life as it was!). However, at the very least more
          of his artwork would’ve been a nice touch. There are a handful of
          pieces included, but the author mentions he worked with all sorts of
          various mediums and I would’ve loved some sculptures or other pieces
          included. I’d rate the book 3.5 stars if I could, merely because the
          book feels like it’s missing a little something, but I would feel
          wrong marking a whole star off of it. As such, my official rating is 4
          out of 4 stars. It’s a quick, light read that still manages to cover
          some deep topics and Glenn ends the book with several books to learn
          more about Quinn and preconception. Anyone who enjoys art,
          biographies, preconception or seeing into the lives of actors should
          give the book a shot, and anyone who is a fan of Anthony Quinn should
          go grab it immediately."
        </p>
        <p>
          <strong>JudyWill453M - OnlineBookClub.org:</strong>
        </p>
        <p>
          "I have to say that I agree with Knight’s review.. This is a great
          read! The author Glenn Harte introduces a completely new concept, the
          concept of precognitive artwork. I have never heard of this before
          reading this book and I have to say after reading it, I now completely
          believe in precognition and that precognitive art is real!!! I do not
          want to give the book away, but I love the way the author works
          through the concept with facts and third parties. The book also
          provides a great history on Quinn’s life and exposes the reader to a
          man who obviously lead an amazing life. The book focuses in on one
          particular painting that has powerful subject matter about 9/11. I was
          happy to read something that introduced a new idea and was not the
          same old stuff that you read over and over. I would encourage all who
          love to read to read this book! It’s a page turner, that flows easily
          and will keep you entertained!!!"
        </p>
      </div>
      <a href="https://www.amazon.com/Prophetic-Imagery-Anthony-Quinn-Precognitive/dp/1483598330/ref=sr_1_1?ie=UTF8&qid=1499458397&sr=8-1&keywords=The+Prophetic+Imagery+of+Anthony+Quinn">
        <Button
          style={{
            borderRadius: 5,
            backgroundColor: "#21b6ae",
            padding: "18px 36px",
            fontSize: "18px",
            color: "#fff",
            marginRight: "5px",
            marginLeft: "5px",
            marginBottom: "50px",
            marginTop: "50px",
          }}
          variant="contained"
        >
          Buy Now
        </Button>
      </a>
    </div>
  );
}

export default App;
